var misc = {
	el: {
		body: $('body'),
		login: $('.login'),
		footer: $('.footer'),
		video: $('.video'),
		videoPlayer: $('video'),
		videoModal: $('.video-modal'),
		videoClose: $('.video--close'),
		menuTrigger: $('.menu-trigger'),
		menuResponsive: $('.responsive-menu')
	},
	events: function () {
		var self = this;
		this.el.login.length ? this.el.footer.css('bottom', 0) : ''

		this.el.video.on('click', function () {
			$(this).next().css({
				'display': 'flex',
				'align-items': 'center'
			});
			self.el.body.css('overflow', 'hidden')
			self.el.videoClose.show();
		})


		this.el.videoModal.add(this.el.videoClose).on('click', function () {
			self.el.videoModal.add(self.el.videoClose).hide()
			self.el.body.removeAttr('style')
			self.el.videoPlayer[0].pause()
			self.el.videoPlayer[0].currentTime = 0;
		})

		this.el.menuTrigger.on('click', function () {
			$(this).find('li').toggleClass('change');
			self.el.menuResponsive.toggleClass('active')
		})
	},

	init: function () {
		this.events();
	}
}
misc.init()
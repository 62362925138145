var login = {
	el: {
		loginContent: $('.login-contents'),
		loginFooter: $('.login-footer'),
		loginError: $('.login-error'),
		loginPassword: $('.js-password')
	},

	events: function () {
		var self = this;

		this.el.loginContent.find('[type=tel]').hide().parent().hide()

		this.el.loginContent.find('.login-back').hide()

		this.el.loginContent.find('.submit').on('click', function (e) {

			if (self.el.loginContent.find('[type=text]').val() === '' || self.el.loginContent.find('[type=password]').val() === '') {
				e.preventDefault()
				self.el.loginError.show()
				self.el.loginContent.add(self.el.loginFooter).hide()
			} else {
				if ($(this).closest('form').hasClass('recovery')) {
					e.preventDefault();
					if (self.el.loginContent.find('[type=text]').val() !== '' && self.el.loginContent.find('[type=password]').val() !== '') {
						$.ajax({
							url: self.el.loginContent.find('form').data('password-endpoint') + self.el.loginContent.find('[type=text]').val(),
							type: 'GET',
							dataType: 'json',
							contentType: 'application/json',
							beforeSend: function () {
								self.el.loginContent.find('.submit').text('A carregar')
							}
						}).done(function (data) {
							self.el.loginContent.find('.submit').hide()
							self.el.loginContent.find('.login-message').text(data)
							setTimeout( function(){
								self.el.loginContent.find('.login-message').hide()
								self.el.loginContent.find('.login-back').show()
							}, 3000)

						}).fail(function (jqXHR) {
							self.el.loginContent.find('.submit').text('Recuperar').attr('disabled', true)
							self.el.loginContent.find('.login-message').text(JSON.parse(jqXHR.responseText))
							setTimeout( function(){
								self.el.loginContent.find('.login-message').text('')
								self.el.loginContent.find('.submit').text('Recuperar').attr('disabled', false)
							}, 3000)
						})
					}
				}
			}


		})

		this.el.loginPassword.on('click', function () {
			self.el.loginContent.find('form').attr('action', self.el.loginContent.find('form').data('password-endpoint')).addClass('recovery').find('p').remove()
			self.el.loginContent.find('.title').text('Recuperar senha')
			self.el.loginContent.find('[type=password]').parent().remove()
			//self.el.loginContent.find('[type=tel]').show().parent().show()
			self.el.loginContent.find('.submit').text('Recuperar')
		})
	},

	init: function () {
		this.events()
	}
}
login.init();
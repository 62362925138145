var table = {
	el: {
		tableSimulator: $('.table-simulator'),
		program: $('.program')
	},

	vars: {
		smallAndUp: window.matchMedia('(min-width: 500px)'),
		largeAndUp: window.matchMedia('(min-width: 1200px)')
	},


	createTable: function(elem) {
		var self = this;		
		$(elem).each(function () {
			var table = $(this); // cache table object
			var head = table.find('thead th');
			var rows = $(elem.prevObject).hasClass('table-simulator') ? table.find('tbody .row__hidden-clone').clone() :  table.find('tbody tr').clone() // appending afterwards does not break original table
	
			// create new table
			var newtable = $(
				'<table class="tableResponsive">' +
				'  <tbody>' +
				'  </tbody>' +
				'</table>'
			);
	
			// cache tbody where we'll be adding data
			var newtable_tbody = newtable.find('tbody');
	
			rows.each(function (i) {
				var cols = $(this).find('td');
				var classname = i % 2 ? 'even' : 'odd';
	
				cols.each(function (k) {
					var new_tr = $('<tr class="' + classname + '"></tr>').appendTo(newtable_tbody);
					new_tr.append(head.clone().get(k));
					new_tr.append($(this));
				});
			});
	
			$(this).after(newtable);
		});
	
	},

	events: function() {		

		if(!this.vars.largeAndUp.matches) 
			this.createTable(this.el.tableSimulator.find('table:first-of-type'))	
		
		if(!this.vars.smallAndUp.matches)
			this.createTable(this.el.program.find('table:first-of-type'))
	},

	init: function () {
		this.events();
	}
}

table.init()
var getMenuItems = {

	el: {
		aside: $('aside'),
		dataFilter: $('[data-filter]')
	},

	events: function () {

		var self = this;

		$.ajax({
			type: "GET",
			url: this.el.aside.data('menu-endpoint'),
			dataType: 'json',
			contentType: 'application/json',
			beforeSend: function () {
				self.el.aside.find('ul').append('<li class="loading">A carregar</li>')
			},

		}).done(function (data) {
			$('.loading').remove();
			$.each(data, function (i) {
				var item = self.el.aside.find('ul').append(`
					<li data-level="1" data-id="${data[i].id}" class="categorie js-products">
						<a href="javascript:;">${data[i].title}<span>(${data[i].available} disponíveis)</span></a>
					</li>
				`);
				item.on('click', function(e) {
					var itemID = $(e.target).parent().data('id');
					self.el.dataFilter.attr('data-id', itemID)
				})
				
			})
		})
	},

	init: function () {
		/products/i.test(window.location.href) ? this.events() : ''
	},

}
getMenuItems.init()
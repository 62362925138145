// var simulator = {
// 	el: {
// 		doc: $(document),
// 		addRow: $('.js-add-row'),
// 		simulatorContainer: $('.table-simulator'),
// 		totalPoints: $('.total-points'),
// 		selectBrands: $('.brands'),
// 		selectType: $('.brands__type'),
// 		brandsPoints: $('.brands__points'),
// 		brandsQuantity: $('.brands__quantity')
// 	},

// 	vars: {
// 		largeAndUp: window.matchMedia('(min-width: 1200px)')
// 	},

// 	addRow: function () {
// 		var self = this;

// 		this.el.addRow.attr('disabled', true).addClass('disabled')		

// 		this.el.addRow.on('click', function () {

// 			$(this).attr('disabled', true).addClass('disabled')
// 			var tableResponsiveOnClick = $('.tableResponsive');

// 			if (self.vars.largeAndUp.matches) {
// 				self.el.simulatorContainer.find('.row__hidden-clone').clone().removeClass('row__hidden-clone').appendTo(self.el.simulatorContainer.find('table').first())

// 			} else {

// 				var even = tableResponsiveOnClick.find('tr').slice(0, 4).clone(),
// 					odd = tableResponsiveOnClick.find('tr.odd').slice(0, 4).clone();

// 				tableResponsiveOnClick.find('tr').last().hasClass('odd') ? even.toggleClass('odd even').appendTo(tableResponsiveOnClick) : odd.appendTo(tableResponsiveOnClick)

// 				if (even.find('.brands__type').find('option').length > 1)
// 					even.find('.brands__type').find('option').not(':first').remove();

// 				if (odd.find('.brands__type').find('option').length > 1)
// 					odd.find('.brands__type').find('option').not(':first').remove();

// 				odd.add(even).find('.brands__points').html('')
// 				odd.add(even).find('.brands__quantity').val('1')
// 			}

// 		})
// 	},

// 	simulatorEvents: function () {

// 		var self = this;

// 		$.each(brands, function (k, v) {
// 			self.el.selectBrands.append('<option value=' + v.FlavourCode + '>' + v.FlavourDesc + '</option>')
// 		})

// 		this.el.doc.on('change', '.brands', function () {
// 			var selfObj = $(this),
// 				value = $(this).find(':selected').val(),
// 				selectTypeDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__type'),
// 				selectTypeMobile = selfObj.closest('tr').next().find('.brands__type'),
// 				inputQuantityDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__quantity'),
// 				inputQuantityMobile = selfObj.closest('tr').siblings().find('.brands__quantity'),
// 				textPointsDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__points'),
// 				textPointsMobile = selfObj.closest('tr').next().next().next().find('.brands__points');

// 			(self.vars.largeAndUp.matches ? selectTypeDesktop : selectTypeMobile).find('option').remove();

// 			(self.vars.largeAndUp.matches ? selectTypeDesktop : selectTypeMobile).append('<option value="-1" selected hidden disabled>Escolha o tipo</option>')

// 			$.each(simulatorData, function (k, v) {
// 				if (value === v.FlavourCode) {

// 					(self.vars.largeAndUp.matches ? selectTypeDesktop : selectTypeMobile).append('<option data-points="' + (v.PackagePoints) * 1 + '" data-campaignmultiples="' +  v.CampaignMultiples +  '" data-campaignpackagepoints="' +  v.CampaignPackagePoints +  '" value=' + v.PresentationSaleCode + '>' + v.PresentationSaleDesc + '</option>')
// 				}
// 			})

// 			if (self.vars.largeAndUp.matches ? inputQuantityDesktop.val() !== 1 : inputQuantityMobile.val() !== 1) {
// 				self.vars.largeAndUp.matches ? inputQuantityDesktop.val('1') : inputQuantityMobile.val('1')
// 			}

// 			(self.vars.largeAndUp.matches ? textPointsDesktop : textPointsMobile).html() !== '' ? (self.vars.largeAndUp.matches ? textPointsDesktop.html('') : textPointsMobile.html('')) : ''

// 			self.calculateTotal();

// 		})

// 		this.el.doc.on('change', '.brands__type', function () {

// 			var selfObj = $(this),
// 				inputQuantityDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__quantity'),
// 				inputQuantityMobile = selfObj.closest('tr').next().find('.brands__quantity'),
// 				textPointsDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__points'),
// 				textPointsMobile = selfObj.closest('tr').next().next().find('.brands__points');

// 			if (self.vars.largeAndUp.matches ? inputQuantityDesktop.val() !== 1 : inputQuantityMobile.val() !== 1)
// 				self.vars.largeAndUp.matches ? inputQuantityDesktop.val('1') : inputQuantityMobile.val('1')

// 			points = (self.vars.largeAndUp.matches ? textPointsDesktop : textPointsMobile).html(selfObj.find(':selected').data('points'))

// 			self.el.addRow.attr('disabled', false).removeClass('disabled')

// 			self.calculateTotal();

			
// 		})

// 		this.el.doc.on('change', '.brands__quantity', function () {

// 			var selfObj = $(this),
// 				selectTypeDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__type'),
// 				selectTypeMobile = selfObj.closest('tr').prev().find('.brands__type'),
// 				textPointsDesktop = selfObj.closest('tr').not('.row__hidden-clone').find('.brands__points'),
// 				textPointsMobile = selfObj.closest('tr').next().find('.brands__points');

// 			var campaignmultiples = (self.vars.largeAndUp.matches ? selectTypeDesktop : selectTypeMobile).find(':selected').data('campaignmultiples');
// 			var campaignpackagepoints = (self.vars.largeAndUp.matches ? selectTypeDesktop : selectTypeMobile).find(':selected').data('campaignpackagepoints');
// 			var points = (self.vars.largeAndUp.matches ? selectTypeDesktop : selectTypeMobile).find(':selected').data('points'); 
// 			var quantityBoxes = selfObj.val();
			
// 			console.log("campaignmultiples: " + campaignmultiples);
// 			console.log("campaignpackagepoints: " + campaignpackagepoints);
// 			console.log("points: " + points);
// 			console.log("quantityBoxes: " + quantityBoxes);
			
// 			if (campaignmultiples > 0 && campaignpackagepoints > 0)
// 			{
// 				var campaignMultiplierProducts = Math.floor(quantityBoxes / campaignmultiples);
// 				var individualProducts = quantityBoxes % campaignmultiples;

// 				console.log("campaignMultiplierProducts: " + campaignMultiplierProducts);
// 				console.log("individualProducts: " + individualProducts);

// 				pointsUpdated = (campaignMultiplierProducts * campaignpackagepoints) + (individualProducts * points);
// 			}
// 			else
// 			{
// 				pointsUpdated = points * quantityBoxes;
// 			}
// 			console.log("pointsUpdated: " + pointsUpdated);
// 			(self.vars.largeAndUp.matches ? textPointsDesktop : textPointsMobile).html(pointsUpdated);

// 			self.calculateTotal();
// 		})
// 	},

// 	calculateTotal: function () {
// 		var self = this,
// 			total = 0,
// 			columnPoints = $('.brands__points');

// 		columnPoints.not(':first').each(function () {
// 			var value = $(this).text();

// 			if (!isNaN(value) && value.length !== 0) {
// 				total += parseInt(value)
// 			}
// 		})

// 		self.el.totalPoints.html(total)
// 	},

// 	init: function () {

// 		var self = this;

// 		if(!this.el.simulatorContainer.length) return
// 		this.addRow();
// 		this.simulatorEvents();
// 	}
// }

// simulator.init()

var simulator = {
	el: {
		formSimulatorClients: $ ('.js-simulator-clients'),
		formSimulatorProduct: $('.js-simulator-product'),
		simulatorResultClientsContainer: $('.simulator__result-clients'),
		simulatorResultSavingsContainer: $('.simulator--savings'),
		btnAddItem: $('.js-add-item')
	},

	events: function() {
		var self = this
		this.el.formSimulatorClients.on('submit', function(e){
			e.preventDefault()
			$.ajax({
				url: $(this).closest('.form').attr('action') + $(this).find('.form__input-text').val(),
				beforeSend: function (data) {
					//console.log(data)
				},
				
			}).done(function(data, textStatus, jqXHR){
				if (jqXHR.status === 200) {
					var data = JSON.parse(jqXHR.responseText)
					var resultsContainer = self.el.simulatorResultClientsContainer
					resultsContainer.next().hide()
					resultsContainer.fadeIn(200)

					for(var prop in data) {
						var fields = resultsContainer.find('[data-field]')
						fields.each(function(i,field){
							if(prop === $(field).data('field')) {
								$(field).html(data[prop])
							}
						})
					}
				}
			})
			.fail(function(data, textStatus, jqXHR){
				console.log('fail: ',data);
				if (data.status === 404) {
					self.el.simulatorResultClientsContainer.hide()
					self.el.simulatorResultClientsContainer.next().fadeIn(200)
				}
				
			})
		})
		this.el.formSimulatorClients.find('.form__input-text').on('input', function(){
			// if($(this).val() !== '') {
			// 	$(this).next().removeClass('btn--disabled')
			// 	$(this).next().attr('disabled', false)
			// } else {
			// 	$(this).next().addClass('btn--disabled')
			// 	$(this).next().attr('disabled', true)
			// }
			switch($(this).val()) {
				case '':
					$(this).next().addClass('btn--disabled')
					$(this).next().attr('disabled', true)
					break
				default:
					$(this).next().removeClass('btn--disabled')
					$(this).next().attr('disabled', false)
			}
		})
	},

	savingsResult: function() {
		var savingsResult = 0
		this.el.simulatorResultSavingsContainer.find('.js-savings-result').each(function(i, val){
			savingsResult += Number($(this).text())
			console.log(savingsResult)
		})

		this.el.simulatorResultSavingsContainer.find('.simulator__result-savings .savings-final-result').text(savingsResult)
	},

	addItem: function() {
		this.el.btnAddItem.on('click', function(e){
			e.preventDefault()
			$(this).before('<div class="added">added</div>')
		})
	},

	init: function () {
		this.events()
		this.savingsResult()
		this.addItem()
	}
}

simulator.init()
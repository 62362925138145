var form = {
	el:{
		form: $('.js-form-options'),
		submitBtn: $('.js-submit'),
		inputTime: $('.form__input-time'),
		inputSelect: $('.form__input-select'),
		inputOption: $('.form__field-option')
	},

	formToJson: function (elem) {
		var unindexed_array = $(elem).serializeArray(),
			indexed_array = {};

		$.map(unindexed_array, function (n, i) {
			$(n).each(function () {
				n.value === 'true' || n.value === 'false' ? n.value = JSON.parse(n.value) : ''
			})
			indexed_array[n['name']] = n['value'];
		});

		return indexed_array;
	},

	formEvents: function () {
		var self = this;

		this.el.form.each(function(i, el){
			$(el).validate({
				errorClass: 'field--error',
				validClass: 'field--valid',
				errorPlacement: function (error, element) {
					error.insertAfter(element);
					if(element.attr('type') =='checkbox') {
						error.insertAfter(element.closest('.form__label').siblings('.btn'))
					}
					//input time
					if(element.attr('data-rule-time') =='true') {
						element.closest('.form__field-option').find('.form__input-time--error').html(error)
					}
					if(element.hasClass('form__input-select')) {
						error.insertAfter(element.closest('.form__field').find('span'))
					}
					// if(error.length) {
					// 	$(el).find('.js-submit').addClass('btn--disabled')
					// } else {
					// 	$(el).find('.js-submit').removeClass('btn--disabled')
					// }
				},
			});
	
			$(el).on('submit', function (e) {
				e.preventDefault()
	
				$(this).find('.js-submit').attr('disabled', true)
				var isValid = $(this).valid();
				if (!isValid) {
					$(this).find('.js-submit').attr('disabled', false)
					return false;
				}
	
				$.ajax({
					url: $(el).attr('action'),
					type: 'POST',
					data: JSON.stringify(self.formToJson(this)),
					contentType: 'application/json',
					dataType: 'json',
					beforeSend: function (data) {
						//console.log(data)
					},
					
				}).done(function(data, textStatus, jqXHR){
					console.log(jqXHR)
					if (jqXHR.status === 200) {
						$(el).slideUp(200);
						$(el).closest('.list__item').removeClass('invalid pending').addClass('standby')
						$(el).closest('.list__item').find('.list__item-state').html('a aguardar')
						$(el).closest('.list__row--accordeon').find('.list__row-message').html($(el).data('msg-success'))
						setTimeout(function(){
							$(el).closest('.list__row--accordeon').slideUp(200)
						},3000)
					}
				})
				.fail(function(data, textStatus, jqXHR){
					console.log(data);
					
				})
			})
		})		
	},

	inputEvents: function() {
		var self = this
		//limit number of chars of input time
		this.el.inputTime.each(function(){
			$(this).on('keyup keydown', function(e){
				if (e.which !== 8) {
					var numChars = $(this).val().length;
					if (numChars === 2) {
						var thisVal = $(this).val();
						thisVal += ':';
						$(this).val(thisVal);
					}
					if (numChars === 5) {
						return false;
					}
				}
			})
			$(this).on('keypress', function(e){
				var k = e.keyCode || e.charCode;
				var c = e.charCode;
				var isArrow = (c == 0 && k >= 37 && k <= 40);
				var isSpecial = ((k == 8) || (k == 9) || (k == 127)) || isArrow; // backspace, tab, delete
				var isOK = ((k >= 48 && k <= 57) || k == 43); // numbers  and +
				return isOK || isSpecial;
			})
		})

		//show options after change of select input
		this.el.inputSelect.each(function(){
			$(this).change(function(e){
				var targetVal = $(e.currentTarget).val()
				self.el.inputOption.each(function(){
					$(this).attr('data-option') === targetVal ? $(this).css('display', 'flex') : $(this).hide()
				})
			})
		})

		// this.el.form.each(function(){
		// 	var submitBtn = $(this).find('.js-submit')
		// 	$(this).find('input').each(function(){
		// 		$(this).change(function(e){
		// 			if($(e.currentTarget).hasClass('field--error')) {
		// 				submitBtn.addClass('btn--disabled')
		// 				console.log($(e.currentTarget), 'invalid')
		// 			} else {
		// 				submitBtn.removeClass('btn--disabled')
		// 				console.log($(e.currentTarget), 'valid')
		// 			}
		// 		})
		// 	})
		// })
	},

	validations: function() {
		$.validator.addMethod('lettersonly', function (value, element) {
			return this.optional(element) || /^([^0-9]*)$/.test(value);
		});

		$.validator.addMethod('phone', function (value, element) {
			return this.optional(element) || /^(\+?)(?:[0-9] ?){8,14}[0-9]$/.test(value);
		});

		$.validator.addMethod('dob', function (value, element) {
			return this.optional(element) || /^(\d{4})(-)(([0-1]{1})([1-2]{1})|([0]{1})([0-9]{1}))(-)(([0-2]{1})([1-9]{1})|([3]{1})([0-1]{1}))/.test(value)
		});

		$.validator.addMethod('email', function (value) {
			return (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/i.test(value))
		})

		$.validator.addMethod('zipcode', function (value, element) {
			return this.optional(element) || /\d{4}\-\d{3}/.test(value);
		});
		$.validator.addMethod('time', function(value) { 
			return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);
		});
		$.validator.addMethod('nif', function (value) {
			//FIX IE for includes method
			if (!Array.prototype.includes) {
			  Object.defineProperty(Array.prototype, "includes", {
				enumerable: false,
				value: function value(obj) {
				  var newArr = this.filter(function (el) {
					return el == obj;
				  });
				  return newArr.length > 0;
				}
			  });
			}
			if (value += '', !['1', '2', '3', '5', '6', '8'].includes(value.substr(0, 1)) && !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(value.substr(0, 2))) return !1;
			var d,
				b = 9 * value[0] + 8 * value[1] + 7 * value[2] + 6 * value[3] + 5 * value[4] + 4 * value[5] + 3 * value[6] + 2 * value[7],
				c = b - 11 * parseInt(b / 11);
			return d = 1 == c || 0 == c ? 0 : 11 - c, value[8] == d;
		});

		$.validator.addMethod('numbersonly', function(value) { 
			return /^\d+$/.test(value);
		});
	},

	init: function () {
		this.formEvents();
		this.inputEvents();
		this.validations();
		//this.savingsResult()
	}
}

form.init();
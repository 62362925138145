var menu = {
	el: {
		menuToggle: $('.header-toggle')
	},

	events: function () {
		this.el.menuToggle.on('click', function() {
			$(this).toggleClass('close')
			$(this).siblings('.header-menu').slideToggle()
		})
	},

	init: function () {
		this.events()
	}
}

menu.init()
var sidebar = {

	el: {
		doc: $(document),
		dropDownMenu: $('.head h2'),
		main: $('.main'),
		aside: $('aside'),
		productDetail: $('.product-detail'),
		productOrder: $('.product-order'),
		filtersBar: $('.filters-bar'),
		filtersBarResponsive: $('.filters-bar-responsive'),
		search: $('.search'),
		breadcrumbs: $('.breadcrumbs')
	},

	vars: {
		largeAndUp: window.matchMedia('(min-width: 1200px)')
	},

	showTable: function () {
		var self = this;
		self.el.main.find('.highlights').hide()
		self.el.main.find('.table').show()
		self.vars.largeAndUp.matches ? self.el.filtersBar.css('display', 'inline-block') : self.el.filtersBarResponsive.css('display', 'inline-block');
	},

	events: function () {

		var self = this;

		this.el.doc.on('click', '.js-products', function (e) {
			self.el.productOrder.hide()
			$("input[name='search']").val('')
			self.el.filtersBar.find('li').siblings().removeClass('selected');
			self.el.filtersBar.find('li').first().addClass('selected');
			self.el.breadcrumbs.find('li').first().text(self.el.aside.find('h2').text())
			self.el.breadcrumbs.find('li').first().next().text(e.target.firstChild.nodeValue).prepend(' > ')
			getList.events(this);
			self.el.productDetail.find('.product-detail-header, .product-detail-container, .product-detail-other-images').remove();
			self.showTable()
			if (!self.vars.largeAndUp.matches)
				self.el.aside.find('ul').hide()



			// if ($(this).data('level') === 1) {
			// 	self.el.breadcrumbs.find('li').first().next().text('')
			// 	self.el.breadcrumbs.find('li').last().text('')
			// 	self.el.breadcrumbs.find('li').first().text(e.target.firstChild.nodeValue)
			// 	$(this).siblings('.subcategorie, .sub-subcategorie').addClass('hidden')
			// }
			// if ($(this).data('level') === 2) {
			// 	self.el.breadcrumbs.find('li').first().next().text(e.target.firstChild.nodeValue).prepend(' > ')
			// 	$(this).nextUntil('.categorie').removeClass('hidden')
			// 	getList.events(this);
			// }

			// if ($(this).data('level') === 3) {
			// 	self.el.breadcrumbs.find('li').last().text(e.target.firstChild.nodeValue).prepend(' > ')
			// 	$(this).nextUntil('.categorie').removeClass('hidden');
			// 	getList.events(this);
			// }

			// $(this).next().data('level') === 2 ? $(this).nextUntil('.categorie').not('.sub-subcategorie').removeClass('hidden') : ''


			// if ($(this).data('level') !== 1) {
			// 	self.el.productDetail.find('.product-detail-header, .product-detail-container, .product-detail-other-images').remove();
			// 	self.showTable()
			// }

		})

		this.el.dropDownMenu.add('.drop').on('click', function () {
			if (!self.vars.largeAndUp.matches) {
				self.el.aside.find('ul').is(':hidden') ? self.el.aside.find('ul').show() : self.el.aside.find('ul').hide()
			}		
		});

		this.el.search.find('.submit').on('click', function (e) {
			e.preventDefault();
			getList.events(this);
			self.showTable();
			self.el.productDetail.hide();
			self.el.breadcrumbs.find('li').text('')
		});

	},

	init: function () {
		this.events();
	},
}
sidebar.init();
var catalog = {
	el: {
		doc: $(document),
		filtersBar: $('.filters-bar'),
		table: $('.table'),
		productDetail: $('.product-detail'),
		productsBlock: $('.products-block'),
		highlights: $('.highlights'),
		body: $('body')
	},
	events: function () {

		var self = this;

		if(!this.el.body.hasClass('layout-v2')) {
			this.el.filtersBar.find('a').on('click', function () {
				$(this).parent().siblings().removeClass('selected');
				$(this).parent().addClass('selected');
				getList.events(this)
			});
	
			this.el.doc.on('change', '.filters-bar-responsive', function(){
				getList.events($(this).find(':selected'))
			})
	
			this.el.doc.on('click', '.product-list .item', function () {
				getDetail.events(this);
				self.el.table.hide();
				self.el.productDetail.show()
			});
	
			this.el.productsBlock.find('.product').on('click', function(){
				getDetail.events(this)
				self.el.table.add(self.el.highlights).hide();
				self.el.productDetail.show()
			})
		}
	},

	init: function () {
		this.events()
	}
}
catalog.init();